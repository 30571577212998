import siteLogo from '~image/logo/logo-black.png'

const headerDefaultConfig = {
  headerClasses:
    'site-header site-header--menu-center light-header position-relative',
  containerFluid: true,
  darkLogo: true,
  defaultLogo: siteLogo,
}
export default headerDefaultConfig

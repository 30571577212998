import React from 'react'

import { Link } from 'gatsby-plugin-react-i18next'

const LinkComponent = ({ children, ...rest }) => {
  return (
    <>
      <Link {...rest}>{children}</Link>
    </>
  )
}

export default LinkComponent

import React from 'react'
import { Helmet } from 'react-helmet'
import favicon from '~image/favicon.ico'
import Header from '../Header'
import { I18nextProvider, Translation } from 'react-i18next'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

export default function Layout(props) {
  const i18n = props.children.props.i18n

  const currentPath = props.pageContext.i18n.originalPath.replace('/', '')
  const translationPageKey = currentPath === '' ? 'home' : currentPath

  // console.log('translationPageKey --', translationPageKey)

  // this variable will served as hackfix until all template pages has been removed
  const pagesThatWillBeSeo = [
    '',
    'information/',
    'services/',
    'about/',
    'survey-request',
    'privacy/',
    'terms-conditions-sobatrp/',
    'terms-conditions-survey/',
  ]

  const convertedLocale =
    props.pageContext.i18n.language === 'en'
      ? 'en_US'
      : props.pageContext.i18n.language === 'id'
      ? 'in_ID'
      : ''
  const siteUrl = process.env.APP_URL || 'https://staging.sobatrp.com'

  return (
    <>
      <I18nextProvider i18n={i18n}>
        {pagesThatWillBeSeo.includes(currentPath) ? (
          <Translation>
            {t => (
              <GatsbySeo
                titleTemplate='%s | SoBatRp'
                language={props.pageContext.language}
                title={t(`seo.${translationPageKey}.title`, { ns: 'seo' })}
                description={t(`seo.${translationPageKey}.description`, {
                  ns: 'seo',
                })}
                openGraph={{
                  url: `${siteUrl}/${props.pageContext.i18n.path}`,
                  title: t(`seo.${translationPageKey}.title`, { ns: 'seo' }),
                  description: t(`seo.${translationPageKey}.description`, {
                    ns: 'seo',
                  }),
                  type: 'website',
                  locale: convertedLocale,
                }}
              />
            )}
          </Translation>
        ) : null}
        <Helmet>
          <link rel='icon' type='image/png' href={favicon} />
        </Helmet>
      </I18nextProvider>
      <I18nextProvider i18n={i18n}>
        <I18nextContext.Provider value={props.pageContext.i18n}>
          <Header />
        </I18nextContext.Provider>
      </I18nextProvider>
      {props.children}
    </>
  )
}
